dmx.Component('calendar', {

  initialData: {
    title: '',
    activeStart: null,
    activeEnd: null,
    currentStart: null,
    currentEnd: null,
  },

  attributes: {
    timezone: {
      type: String,
      default: 'local',
      // local and UTC, for named timezones use moment-timezone or luxon plugin
      // https://fullcalendar.io/docs/timeZone#named-time-zones
    },
    
    locale: {
      type: String,
      default: undefined,
      // https://fullcalendar.io/docs/locale
    },

    date: {
      type: String,
      default: undefined,
      // https://fullcalendar.io/docs/initialDate
    },

    height: {
      type: String,
      default: undefined,
      // https://fullcalendar.io/docs/height
    },

    aspectRatio: {
      type: Number,
      default: 1.35,
      // https://fullcalendar.io/docs/aspectRatio
    },

    view: {
      // dayGridMonth, dayGridWeek, dayGridDay, dayGridYear
      // timeGridWeek, timeGridDay
      // listDay, listWeek, listMonth, listYear
      // multiMonthYear
      // timelineDay, timelineWeek, timelineMonth, timelineYear
      // resourceTimeGridDay
      type: String,
      default: 'dayGridMonth',
      // https://fullcalendar.io/docs/initialView
    },

    views: {
      type: String,
      default: '',
      // used in headerToolbar
    },

    theme: {
      type: String,
      default: 'standard',
      enum: ['standard', 'bootstrap', 'bootstrap5'],
      // https://fullcalendar.io/docs/themeSystem
    },

    hideNonCurrentDates: {
      type: Boolean,
      default: false,
      // https://fullcalendar.io/docs/showNonCurrentDates
    },

    selectable: {
      type: Boolean,
      default: false,
      // https://fullcalendar.io/docs/selectable
    },

    editable: {
      type: Boolean,
      default: false,
      // https://fullcalendar.io/docs/editable
    },

    longPressDelay: {
      type: Number,
      default: 1000,
      // https://fullcalendar.io/docs/longPressDelay
    },

    noEventOverlap: {
      type: Boolean,
      default: false,
      // https://fullcalendar.io/docs/eventOverlap
    },

    businessHours: {
      type: [Boolean, Object, Array],
      default: false,
      // https://fullcalendar.io/docs/businessHours
    },

    /* Use fixedWeekCount on month/multimonth view options instead
    noFixedWeekCount: {
      type: Boolean,
      default: false,
      // https://fullcalendar.io/docs/fixedWeekCount
    },
    */

    eventOrder: {
      type: String,
      default: 'start,-duration,allDay,title',
      // https://fullcalendar.io/docs/eventOrder
    },

    eventLimit: {
      type: Boolean,
      default: false,
      // https://fullcalendar.io/docs/eventLimit
    },

    nowIndicator: {
      type: Boolean,
      default: false,
      // https://fullcalendar.io/docs/nowIndicator
    },

    viewsOptions: {
      type: Object,
      default: {},
      // https://fullcalendar.io/docs/view-specific-options
    },

    eventConstraint: {
      type: [String, Object],
      default: null,
      // https://fullcalendar.io/docs/eventConstraint
    },

    selectConstraint: {
      type: [String, Object],
      default: null,
      // https://fullcalendar.io/docs/selectConstraint
    },

    googleCalendarApiKey: {
      type: String,
      default: '',
      // https://fullcalendar.io/docs/google-calendar
    },

    bsTooltip: {
      type: Boolean,
      default: false,
    },

    bsTooltipPlacement: {
      type: String,
      default: '"top"',
    },

    bsTooltipTitle: {
      type: String,
      default: 'event.extendedProps.description || event.title',
    },

    bsTooltipHtml: {
      type: String,
      default: 'false',
    },

    // allow extending the config
    config: {
      type: Object,
      default: {},
    },

    // TODO: constraint
  },

  methods: {
    gotoDate (date) {
      this._calendar.gotoDate(date);
    },

    updateSize () {
      this._calendar.updateSize();
    },

    prev () {
      this._calendar.prev();
    },

    next () {
      this._calendar.next();
    },

    prevYear () {
      this._calendar.prevYear();
    },

    nextYear () {
      this._calendar.nextYear();
    },

    today () {
      this._calendar.today();
    },
  },

  events: {
    dateclick: MouseEvent, // interaction plugin
    eventclick: MouseEvent,
    eventmouseenter: MouseEvent,
    eventmouseleave: MouseEvent,
    eventdrop: Event,
    eventresize: Event,
    select: Event,
  },

  render (node) {
    this._calendar = new FullCalendar.Calendar(node, {
      timeZone: this.props.timezone,
      locale: this.props.locale || navigator.language,
      initialDate: this.props.date,
      initialView: this.props.view,
      height: this.props.height, // TODO: check if this is a valid value and convert number when needed
      aspectRatio: this.props.aspectRatio,
      themeSystem: this.props.theme,
      showNonCurrentDates: !this.props.hideNonCurrentDates,
      selectable: this.props.selectable,
      editable: this.props.editable,
      longPressDelay: this.props.longPressDelay,
      eventOrder: this.props.eventOrder,
      eventOverlap: !this.props.noEventOverlap,
      eventConstraint: this.props.eventConstraint,
      selectConstraint: this.props.selectConstraint,
      businessHours: typeof this.props.businessHours == 'string' ? this.props.businessHours != 'false' : this.props.businessHours,
      dayMaxEventRows: this.props.eventLimit,
      nowIndicator: this.props.nowIndicator,
      googleCalendarApiKey: this.props.googleCalendarApiKey,
      views: this.props.viewsOptions,

      // callbacks
      datesSet: this._datesSet.bind(this), // https://fullcalendar.io/docs/datesSet (previously datesRender)
      dateClick: this._dateClick.bind(this), // https://fullcalendar.io/docs/dateClick
      eventClick: this._eventClick.bind(this), // https://fullcalendar.io/docs/eventClick
      eventMouseEnter: this._eventMouseEnter.bind(this), // https://fullcalendar.io/docs/eventMouseEnter
      eventMouseLeave: this._eventMouseLeave.bind(this), // https://fullcalendar.io/docs/eventMouseLeave
      eventDrop: this._eventDrop.bind(this), // https://fullcalendar.io/docs/eventDrop
      eventResize: this._eventResize.bind(this), // https://fullcalendar.io/docs/eventResize
      eventDidMount: this._eventDidMount.bind(this), // https://fullcalendar.io/docs/event-render-hooks
      eventWillUnmount: this._eventWillUnmount.bind(this), // https://fullcalendar.io/docs/event-render-hooks
      select: this._select.bind(this), // https://fullcalendar.io/docs/select-callback

      headerToolbar: {
        start: 'today prev,next',
        center: 'title',
        end: this.props.views.toString(),
      },

      ...this.props.config,
    });

    this.$parse();
    
    node.innerHTML = '';

    this._calendar.render();
  },

  performUpdate (updatedProps) {
    this._calendar.batchRendering(() => {
      if (updatedProps.has('date')) {
        this._calendar.gotoDate(this.props.date);
      }

      if (updatedProps.has('view')) {
        this._calendar.changeView(this.props.view);
      }

      if (updatedProps.has('theme')) {
        this._calendar.setOption('themeSystem', this.props.theme);
      }

      if (updatedProps.has('height')) {
        this._calendar.setOption('height', this.props.height);
      }

      if (updatedProps.has('aspectRatio')) {
        this._calendar.setOption('aspectRatio', this.props.aspectRatio);
      }

      if (updatedProps.has('hideNonCurrentDates')) {
        this._calendar.setOption('showNonCurrentDates', !this.props.hideNonCurrentDates);
      }

      if (updatedProps.has('selectable')) {
        this._calendar.setOption('selectable', this.props.selectable);
      }

      if (updatedProps.has('editable')) {
        this._calendar.setOption('editable', this.props.editable);
      }

      if (updatedProps.has('longPressDelay')) {
        this._calendar.setOption('longPressDelay', this.props.longPressDelay);
      }

      if (updatedProps.has('eventOrder')) {
        this._calendar.setOption('eventOrder', this.props.eventOrder);
      }

      if (updatedProps.has('noEventOverlap')) {
        this._calendar.setOption('eventOverlap', !this.props.noEventOverlap);
      }

      if (updatedProps.has('businessHours')) {
        this._calendar.setOption('businessHours', typeof this.props.businessHours == 'string' ? this.props.businessHours != 'false' : this.props.businessHours);
      }

      if (updatedProps.has('eventLimit')) {
        this._calendar.setOption('dayMaxEventRows', this.props.eventLimit);
      }

      if (updatedProps.has('nowIndicator')) {
        this._calendar.setOption('nowIndicator', this.props.nowIndicator);
      }

      // Not implemented yet in calendar
      if (updatedProps.has('viewsOptions')) {
        this._calendar.setOption('views', this.props.viewsOptions);
      }

      if (updatedProps.has('views')) {
        this._calendar.setOption('headerToolbar', {
          start: 'today prev,next',
          center: 'title',
          end: this.props.views.toString(),
        });
      }
    });
  },

  destroy () {
    if (this._calendar) {
      this._calendar.destroy();
    }
  },

  // TODO: deprecate this, use JSON or expression instead which is the App Connect 2 default way
  // we overwrite the default $parseAttributes method to parse some special non-standard attributes
  $parseAttributes (node) {
    dmx.BaseComponent.prototype.$parseAttributes.call(this, node);

    // used to keep track of the business hours from custom attributes
    this._businessHours = {};

    // TODO: this should be done using the default business-hours attribute
    // TODO: like `dmx-bind:business-hours="[{...},{...}]"`
    dmx.dom.getAttributes(node).forEach((attr) => {
      if (attr.name == 'business-hours') {
        this.$watch(attr.value, (value) => {
          if (value != null) {
            // the key is the first modifier of the attribute
            const key = Object.keys(attr.modifiers)[0];
            // we need to temporarily store the value
            this._businessHours[key] = value;
            // we need to convert the object to an array
            this.props.businessHours = Object.values(this._businessHours);
          }
        });
      }
    });

    // we need to parse the views options attributes and map them to the correct view
    // this is here for backwards compatibility and does not support custom views or premium views
    const viewOptionsMappings = {
      'day': 'day', // options apply to dayGridDay and timeGridDay views
      'week': 'week', // options apply to dayGridWeek and timeGridWeek views
      'day-grid': 'dayGrid', // options apply to dayGridMonth, dayGridWeek, and dayGridDay views
      'day-grid-month': 'dayGridMonth',
      'day-grid-week': 'dayGridWeek',
      'day-grid-day': 'dayGridDay',
      'time-grid': 'timeGrid', // options apply to timeGridWeek and timeGridDay views
      'time-grid-week': 'timeGridWeek',
      'time-grid-day': 'timeGridDay',
      'list': 'list', // options apply to listDay, listWeek, listMonth, and listYear views
      'list-day': 'listDay',
      'list-week': 'listWeek',
      'list-month': 'listMonth',
      'list-year': 'listYear',
      'multi-month': 'multiMonth', // options apply to multiMonthYear view
      'multi-month-year': 'multiMonthYear',
    };

    // TODO: insteaf of `views-options:day="{...}"` it should be `dmx-bind:views-options"{days:{...}}"`
    // TODO: all the other view options should be handled the same way into a single attribute
    for (const attr of node.attributes) {
      if (attr.name.startsWith('views-options')) {
        const view = viewOptionsMappings[attr.name.slice(14)];
        if (view) {
          this.$watch(attr.value, (value) => {
            if (value != null) {
              // make sure to set a new object to trigger the update
              this.props.viewsOptions = { ...this.props.viewsOptions, [view]: value };
            }
          });
        }
      }

      // special handling for the constraint business hours attribute
      if (attr.name == 'constraint.business-hours') {
        // old behavior was to set selectConstraint instead of eventConstraint
        // TODO: insteaf of `constraint.group="groupId"` it should be `select-constraint="businessHours"`
        this.props.selectConstraint = 'businessHours';
      }

      // special handling for the constraint group attribute
      if (attr.name == 'constraint.group') {
        // old behavior was to set selectConstraint instead of eventConstraint\
        // TODO: insteaf of `constraint.group="groupId"` it should be `select-constraint="groupId"`
        this.props.selectConstraint = attr.value;
      }

      // special handling for the constraint dynamic attribute
      if (attr.name == 'dmx-bind:constraint') {
        this.$watch(attr.value, (value) => {
          if (value != null) {
            // old behavior was to set selectConstraint instead of eventConstraint
            // TODO: insteaf of `dmx-bind:constraint="{...}"` it should be `dmx-bind:select-constraint="{...}`
            this.props.selectConstraint = value;
          }
        });
      }

      // special handling for the select constraint business hours attribute
      if (attr.name == 'select-constraint.business-hours') {
        // TODO: insteaf of `constraint.group="groupId"` it should be `select-constraint="businessHours"`
        this.props.selectConstraint = 'businessHours';
      }

      // special handling for the select constraint group attribute
      if (attr.name == 'select-constraint.group') {
        // TODO: insteaf of `constraint.group="groupId"` it should be `select-constraint="groupId"`
        this.props.selectConstraint = attr.value;
      }

      // special handling for the event constraint business hours attribute
      if (attr.name == 'event-constraint.business-hours') {
        // TODO: insteaf of `event-constraint.group="groupId"` it should be `event-constraint="businessHours"`
        this.props.eventConstraint = 'businessHours';
      }

      // special handling for the event constraint group attribute
      if (attr.name == 'event-constraint.group') {
        // TODO: insteaf of `event-constraint.group="groupId"` it should be `event-constraint="groupId"`
        this.props.eventConstraint = attr.value;
      }
    }
  },

  _getEventData (event) {
    return {
      id: event.id,
      title: event.title,
      start: event.startStr,
      end: event.endStr,
      allDay: event.allDay,
      extendedProps: event.extendedProps,
    };
  },

  _parseDate (date) {
    const utc = this.props.timezone === 'UTC';
    const year = date[utc ? 'getUTCFullYear' : 'getFullYear']();
    const month = date[utc ? 'getUTCMonth' : 'getMonth']() + 1;
    const day = date[utc ? 'getUTCDate' : 'getDate']();
    return `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}T00:00:00${utc ? 'Z' : ''}`;
  },

  _datesSet (dateInfo) {
    this.set({
      title: dateInfo.view.title,
      activeStart: this._parseDate(dateInfo.view.activeStart),
      activeEnd: this._parseDate(dateInfo.view.activeEnd),
      currentStart: this._parseDate(dateInfo.view.currentStart),
      currentEnd: this._parseDate(dateInfo.view.currentEnd),
    });
  },

  _dateClick (dateClickInfo) {
    const cancelled = !this.dispatchEvent('dateclick', dateClickInfo.jsEvent, {
      date: dateClickInfo.dateStr,
      allDay: dateClickInfo.allDay,
    });

    if (!cancelled) {
      dateClickInfo.jsEvent.preventDefault();
    }
  },

  _eventClick (eventClickInfo) {
    const cancelled = !this.dispatchEvent('eventclick', eventClickInfo.jsEvent, {
      event: this._getEventData(eventClickInfo.event),
    });

    if (!cancelled) {
      eventClickInfo.jsEvent.preventDefault();
    }
  },

  _eventMouseEnter (mouseEnterInfo) {
    this.dispatchEvent('eventmouseenter', mouseEnterInfo.jsEvent, {
      event: this._getEventData(mouseEnterInfo.event),
    });
  },

  _eventMouseLeave (mouseLeaveInfo) {
    this.dispatchEvent('eventmouseleave', mouseLeaveInfo.jsEvent, {
      event: this._getEventData(mouseLeaveInfo.event),
    });
  },

  _eventDrop (eventDropInfo) {
    this.dispatchEvent('eventdrop', eventDropInfo.jsEvent, {
      event: this._getEventData(eventDropInfo.event),
      oldEvent: this._getEventData(eventDropInfo.oldEvent),
    });
  },

  _eventResize (eventResizeInfo) {
    this.dispatchEvent('eventresize', eventResizeInfo.jsEvent, {
      event: this._getEventData(eventResizeInfo.event),
      oldEvent: this._getEventData(eventResizeInfo.oldEvent),
    });
  },

  _eventDidMount (info) {
    if (this.props.bsTooltip && window.bootstrap) {
      const scope = dmx.DataScope({
        event: this._getEventData(info.event),
      }, this);

      const options = {
        placement: dmx.parse(this.props.bsTooltipPlacement, scope),
        title: dmx.parse(this.props.bsTooltipTitle, scope),
        html: dmx.parse(this.props.bsTooltipHtml, scope),
      };

      if (bootstrap.Tooltip.VERSION.startsWith('4.')) {
        $(info.el).tooltip(options);
      } else {
        new bootstrap.Tooltip(info.el, options);
      }
    }
  },

  _eventWillUnmount (info) {
    if (this.props.bsTooltip && window.bootstrap) {
      if (bootstrap.Tooltip.VERSION.startsWith('4.')) {
        $(info.el).tooltip('dispose');
      } else {
        const tooltip = bootstrap.Tooltip.getInstance(info.el);
        if (tooltip) tooltip.dispose();
      }
    }
  },

  _select (selectionInfo) {
    const cancelled = !this.dispatchEvent('select', selectionInfo.jsEvent, {
      start: selectionInfo.startStr,
      end: selectionInfo.endStr,
      allDay: selectionInfo.allDay,
    });

    if (!cancelled) {
      selectionInfo.jsEvent.preventDefault();
    }
  },

});